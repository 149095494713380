import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../core/alert.service';
import { Observable, ReplaySubject } from 'rxjs';
import { Swot } from './swot';

@Injectable()
export class SwotService {

  private swotUrl = environment.cubCloudUrl + 'swot';

  constructor(
    private http: HttpClient,
    private alertService: AlertService
  ) {
  }

  getSwot(propertyKey: number): Observable<Swot[]> {
    const url = `${this.swotUrl}` + '/property/' + propertyKey;
    let list = this.http.get<Swot[]>(url, { withCredentials: true });
    return list;
  }

  addSwot(swot: Swot): Observable<boolean> {
    const url = `${this.swotUrl}`;
    return this.http.post<boolean>(url, swot, { withCredentials: true });
  }
}
