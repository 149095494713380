import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { Subscription } from 'rxjs';
import { LoggerService } from './core/logger.service';
import { LookupTable } from './core/lookup-table';
import { LookupService } from './core/lookup.service';
import { AlertService } from './core/alert.service';
import { UserService } from './core/user.service';
import { PortalService } from './core/portal.service';
import { Alert, AlertType } from './core/alert';
import { VersionCheckService } from './core/version-check.service';
import { VersionAlertComponent } from './core/version-alert/version-alert.component';
import { MatDialog } from '@angular/material/dialog';
import { SelectivePreloadingStrategyService } from './selective-preloading-strategy.service';
import { Constants } from './core/constants';
import { PopupComponent } from './core/popup/popup.component';
import { User } from './user/user';
import { AuthService } from './auth.service';
import { AccountInfo } from '@azure/msal-browser';

@Component({
    selector: 'cub-app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet]
})
export class AppComponent implements OnInit, OnDestroy {
  user: User;
  activeAccount: AccountInfo;
  title = 'app';
  system: any;
  showList: boolean;
  subscription: Subscription;
  loadNewDataNow = false;
  modules: string[];
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  constructor(
    public authService: AuthService,   // was already here
    private router: Router,
    private lookupService: LookupService,
    private userService: UserService,
    private loggerService: LoggerService,
    private alertService: AlertService,
    private portalService: PortalService,
    public dialog: MatDialog,
    private versionCheckService: VersionCheckService,
    preloadStrategy: SelectivePreloadingStrategyService
  ) {
    this.modules = preloadStrategy.preloadedModules;
    if (environment.localDev) {
      this.favIcon.href = 'assets/img/favicon-yellow.ico?v=4';
    } else if (environment.remote) {
      this.favIcon.href = 'assets/img/favicon-yellow.ico?v=4';
    } else if (environment.qa) {
      this.favIcon.href = 'assets/img/favicon-orange.ico?v=4';
    } else if (environment.production) {
      this.favIcon.href = 'favicon2.ico?v=4';
    }
  }

  ngOnInit(): void {
    // console.log('app component ngOnInit Firing');
    this.authService.updateLoggedInStatus(); // was already here
    // this code will prevent errors when user object in localStorage is initialized, but doesn't contain user acct info
    // this gets the "basic" user info from azure auth service (just username, ids, etc)
    let validateUserObj = new User();
    validateUserObj = JSON.parse(localStorage.getItem('user')) as User;
    if ((validateUserObj === null) || (validateUserObj.accountName === null || validateUserObj.accountName === undefined)) {
      this.activeAccount = this.authService.getActiveAccount();
      let userObj = new User();
      userObj.accountName = 'CENTERPOINT-PRO\\' + this.activeAccount.username.replace('@centerpoint.com', '');
      userObj.azureAuthCode = '';
      localStorage.setItem('user', JSON.stringify(userObj));
    }

    //this will go out and grab the latest user information, including security and listViews
    this.userService.refreshUserFromServer();

    this.userService.userData$.subscribe(u => {
      // console.log('userData$.subscribe', u);
      this.user = u;
      localStorage.setItem('user', JSON.stringify(u));
      this.prepareCUB();
      this.router.initialNavigation();
    });
  }

  prepareCUB() {
    this.loggerService.logPageVisit('angular cub app', 'app.component.ts');
    this.userService.getMenu(true);
    this.userService.loadSecurity(true);
    this.portalService.getSystemData();
    this.portalService.getQuarterlyMetrics();
    this.getLookups();
    if (!environment.localDev) {
      this.versionCheckService.initVersionCheck(environment.versionCheckURL);  // this initiates the service to check for a new version of the app (to reload or warn the user)
      this.alertService.getAlert().subscribe((alert: Alert) => {
        if (alert !== undefined && alert !== null && alert.type === AlertType.Version) {
          let dialogRef = this.dialog.open(VersionAlertComponent, {
            disableClose: true,
            width: '600px',
            data: alert.message
          });
        }
      });
    }
    this.determinePopupStatus();
  }

  determinePopupStatus(): void {
    if (localStorage.getItem('lastPortalLoad') !== null) {
      // if there is a new popup today and the last time they opened the portal was before today
      if (moment().format('MM/DD/YYYY') === moment(Constants.NewPopupDate, 'MM/DD/YYYY').format('MM/DD/YYYY') &&
        moment(localStorage.getItem('lastPortalLoad')).isBefore(moment(Constants.NewPopupDate))) {
        this.showSystemPopup();
      }
    } else { // if there is no portal load date and today is a popup date, fire it up!
      if (moment().format('MM/DD/YYYY') === moment(Constants.NewPopupDate, 'MM/DD/YYYY').format('MM/DD/YYYY')) {
        this.showSystemPopup();
      }
    }
    localStorage.setItem('lastPortalLoad', moment().format('MM/DD/YYYY'));
  }

  showSystemPopup(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      disableClose: false,
      width: '800px',
      height: '500px',
      position: { top: '100px' },
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getLookups(): void {
    // this.loggerService.log('local storage lookup: ' + localStorage.getItem('lookupTablesTime'));
    if (this.loadNewDataNow || localStorage.getItem('lookupTablesTime') == null || moment([]).isAfter(moment(localStorage.getItem('lookupTablesTime')).add(30, 'm'))) {
      this.lookupService.getLookupTables()
        .subscribe((tables: LookupTable[]) => {
          localStorage.setItem('lookupTables', JSON.stringify(tables));
          localStorage.setItem('lookupTablesTime', moment([]).toISOString());
        });
    }
  }

  ngOnDestroy() { // prevent memory leak when component destroyed
    this.subscription.unsubscribe();
  }
}
