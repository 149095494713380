export class SystemData {
  propertyCountHistory: number[];
  propertySFHistory: number[];
  leaseExpirationsBySF: number[];
  leaseExpirationsByRent: number[];
  landAcresHistory: number[];
  investmentClosedYTDCount: string;
  investmentClosedYTDSF: string;
  investmentLOICount: string;
  investmentLOISF: string;
  sysSummaryData: SystemSummaryData[];
  intermodalSubmittalPeriod: string;
  portfolioStats: PortfolioStats[];
  keyMetrics: PortalMetrics;
  gauges: PortalGauge[];
  calendarEvents: CalendarEvent[];
  calendarObjects: CalendarObject[];
  regionData: MasterRegionData[];
  portfolioStateData: PortfolioStateData[];
  vacancyAge: VacancyAge[];
  baseballTeams: BaseballTeam[];
  arAgingData: ARAgingData;
  weather: WeatherData[];

  weatherCurrentTemp: string;
  weatherCurrentCondition: string;
  weatherTomorrowTempHigh: string;
  weatherTomorrowTempLow: string;
  weatherTomorrowCondition: string;
  weatherTodayTempHigh: string;
  weatherTodayTempLow: string;
  weatherTodayCondition: string;
  propertyCount: string;
  propertyTotalSF: string;
  leaseCount: string;
  occupancy: string;
  investmentUnderContractCount: string;
  investmentUnderContractSF: string;
  importantDataDevelopment: string;
  secondaryDataDevelopment: string;
  lunchMenuDays: LunchMenu[];

  constructor() {
  }
}

export class ARAgingData {
  total: number;
  futureCharges: number;
  zeroToThirty: number;
  thirtyPlus: number;
  sixtyPlus: number;
  ninetyPlus: number;

  constructor() {
  }
}

export class SystemSummaryData {
  typeName: string;
  tableKey: number;
  typeKey: number;
  name: string;
  totalCount: number;
  totalSF: number;
  hTMLColor: string;
  sortOrder: number;

  constructor() {
  }
}

export class WeatherData {
  time: Date;
  tempMin: number;
  tempMax: number;
  sunrise: Date;
  sunset: Date;
  conditions: string;

  constructor() {
  }
}

export class PortalMetrics {
  metricNOIYTDBudget: number;
  metricNOIYTDActual: number;
  metricNOIYTDVariance: number;
  metricNOIYearEndBudget: number;
  metricNOIYearEndForecast: number;
  metricNOIYearEndVariance: number;
  metricRevenueYTDBudget: number;
  metricRevenueYTDActual: number;
  metricRevenueYTDVariance: number;
  metricRevenueYearEndBudget: number;
  metricRevenueYearEndForecast: number;
  metricRevenueYearEndVariance: number;
  metricFFOYTDBudget: number;
  metricFFOYTDActual: number;
  metricFFOYTDVariance: number;
  metricFFOYearEndBudget: number;
  metricFFOYearEndForecast: number;
  metricFFOYearEndVariance: number;
  metricDebtCapYTDBudget: number;
  metricDebtCapYTDActual: number;
  metricDebtCapYTDVariance: number;
  metricDebtCapYearEndBudget: number;
  metricDebtCapYearEndForecast: number;
  metricDebtCapYearEndVariance: number;
  metricUnprodAssYTDBudget: number;
  metricUnprodAssYTDActual: number;
  metricUnprodAssYTDVariance: number;
  metricUnprodAssYearEndBudget: number;
  metricUnprodAssYearEndForecast: number;
  metricUnprodAssYearEndVariance: number;
  metricTotalAssYTDBudget: number;
  metricTotalAssYTDActual: number;
  metricTotalAssYTDVariance: number;
  metricTotalAssYearEndBudget: number;
  metricTotalAssYearEndForecast: number;
  metricTotalAssYearEndVariance: number;

  constructor() {
  }
}

export class PortalGauge {
  category: string;
  description: string;
  weight: number;
  inPlace: number;
  inSight: number;
  total: number;
  target: number;
  threshold: number;
  maximum: number;
  bonusEarnedPctTarget: number;
  weightedAvgPctTarget: number;
  gaugeMeasure: string;
  gaugeColor: string;
  gaugeColor2: string;
  popoverTitle: string;
  popoverDesc: string;
  formatString: string;
  isCustomGauge: boolean;
  hasInverseImpact: boolean;
  totalFormatted: string;
  targetFormatted: string;
  inPlaceFormatted: string;
  inSightFormatted: string;
  thresholdFormatted: string;
  maximumFormatted: string;

  constructor() {
  }
}
export class CalendarEvent {
  key: number;
  id: number;
  title: string;
  url: string;
  calendarKey: number;
  calendarName: string;
  startDt: Date;
  endDt: Date;
  calendarStartDt: Date;
  calendarEndDt: Date;
  asShownOnCalendarStartDt: Date;
  asShownOnCalendarEndDt: Date;
  workingDays: number;
  cakeEvent: boolean;
  priorEvent: boolean;

  constructor() {
  }
}
export class CalendarObject {
  id: string;
  title: string;
  class: string;
  week: number;
  eventKey: number;
  topInterval: number;
  leftInterval: number;
  widthInterval: number;
  url: string;
  eventId: string;
  cakeEvent: boolean;
  priorEvent: boolean;
  calendarKey: number;
  calendarName: string;

  constructor() {
  }
}

export class MasterRegionData {
  dataType: string;
  rowData: MasterRegionDataRow[];

  constructor() {
  }
}

export class MasterRegionDataRow {
  categoryName: string;
  totalNumber: number;
  totalSF: number;
  totalValue: number;

  constructor() {
  }
}

export class PortfolioStateData {
  stateID: string;
  region: string;
  sqFt: number;
  totalCount: number;
  appraisedValue: number;
  opacity: number;

  constructor() {
    this.opacity = 1;
  }
}

export class VacancyAge {
  yearsVacant: number;
  sqFt: number;
  totalCount: number;

  constructor() {
  }
}

export class BaseballTeam {
  iD: number;
  code: number;
  name: string;
  city: string;
  leagueID: number;
  leagueName: string;
  division: string;
  sortOrder: number;
  overUnder: number[];
  currentWins: number;
  currentLosses: number;
  homeWins: number;
  homeLosses: number;
  roadWins: number;
  roadLosses: number;
  highWaterMark: number;
  lowWaterMark: number;

  constructor() {
  }
}

export class PortfolioStats {
  year: number;
  sqFt: number;
  properties: number;
  tenants: number;
  acres: number;
  navValue: number;
  sharePrice: number;
  occupancy: number;

  constructor() {
  }
}

export class LunchMenu {
  breakfast: string;
  entree: string;
  deli: string;
  soup: string;
  weekDayName: number;

  constructor() {
  }
}
