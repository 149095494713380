import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { UserMenu } from '../../user/user-menu';

import {
  faUsers, faNewspaper, faShovel, faRecycle, faUsdSquare, faFileSignature, faClock, faBuilding,
  faStar, faUserCog, faUser, faPlane, faCogs, faKey, faHeartbeat, faCalculator, faIdCard, faPiggyBank, faUnlock, faRightFromBracket, faPersonDigging,
} from '@fortawesome/pro-light-svg-icons';
import { AuthService } from '../../auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf } from '@angular/common';
import { LoggerService } from '../logger.service';



@Component({
  selector: 'cub-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, MatMenuModule, FontAwesomeModule, RouterLink]
})
export class MenuItemComponent {

  @Input() menuItem: UserMenu;

  faUsers = faUsers;
  faUser = faUser;
  faPlane = faPlane;
  faCogs = faCogs;
  faCalculator = faCalculator;
  faNewspaper = faNewspaper;
  faShovel = faShovel;
  faRecycle = faRecycle;
  faUsdSquare = faUsdSquare;
  faFileSignature = faFileSignature;
  faClock = faClock;
  faBuilding = faBuilding;
  faStar = faStar;
  faUserCog = faUserCog;
  faKey = faKey;
  faHeartbeat = faHeartbeat;
  faIdCard = faIdCard;
  faPiggyBank = faPiggyBank;
  faUnlock = faUnlock;
  faRightFromBracket = faRightFromBracket;
  faPersonDigging = faPersonDigging;


  constructor(
    public dialog: MatDialog,
    private loggerService: LoggerService,
    private router: Router,
    public authService: AuthService,   // was already here
    private route: ActivatedRoute
  ) { }

  trackClick() {
    this.loggerService.logPageVisit('new button', this.menuItem.name);
  }

  menuClick(menu: UserMenu) {
    this.loggerService.logPageVisit('new button', menu.name);
    if (menu.method === 'logout') {
      this.authService.logout();
    }
  }

  isIcon(icon: string): boolean {
    if (icon) {
      return icon.startsWith('fa');
    } else {
      return false;
    }
  }

  getLink(url: string): string {
    if (document.location.href.startsWith("https")) {
      url = url.replace("http://cub/", "https://cub.centerpoint.com/");
    }
    return url;
  }

  getIcon(icon: string): any {
    let fa = faUser;
    switch (icon) {
      case 'faUser':
        fa = faUser;
        break;
      case 'faUsers':
        fa = faUsers;
        break;
      case 'faPlane':
        fa = faPlane;
        break;
      case 'faCogs':
        fa = faCogs;
        break;
      case 'faCalculator':
        fa = faCalculator;
        break;
      case 'faNewspaper':
        fa = faNewspaper;
        break;
      case 'fa-shovel':
      case 'faShovel':
        fa = faShovel;
        break;
      case 'faRecycle':
        fa = faRecycle;
        break;
      case 'faUsdSquare':
        fa = faUsdSquare;
        break;
      case 'fa-file-text-o':
      case 'faFileSignature':
        fa = faFileSignature;
        break;
      case 'faClock':
        fa = faClock;
        break;
      case 'faBuilding':
      case 'fa-building':
        fa = faBuilding;
        break;
      case 'faStar':
        fa = faStar;
        break;
      case 'faPiggyBank':
        fa = faPiggyBank;
        break;
      case 'faKey':
        fa = faKey;
        break;
      case 'faIdCard':
        fa = faIdCard;
        break;
      case 'faUnlock':
        fa = faUnlock;
        break;
      case 'faRightFromBracket':
        fa = faRightFromBracket;
        break;
      case 'fa-personDigging':
      case 'faPersonDigging':
        fa = faPersonDigging;
        break;
      default:
        break;
    }
    return fa;
  }

}
