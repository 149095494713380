


import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TopMenuComponent } from './top-menu/top-menu.component';
import { LeftMenuComponent } from './left-menu/left-menu.component';
import { LoggerService } from './logger.service';
import { throwIfAlreadyLoaded } from './module-import-gaurd';
import { LeftMenuButtonComponent } from './left-menu-button/left-menu-button.component';

import { SideSubMenuComponent } from './side-sub-menu/side-sub-menu.component';
import { SubMenuService } from './sub-menu.service';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert.service';

import { SearchResultPopupComponent } from './search-result-popup/search-result-popup.component';
import { SearchService } from './search.service';
import { SearchMainComponent } from './search-main/search-main.component';
import { MenuService } from './menu.service';
import { LookupService } from './lookup.service';
import { UserService } from './user.service';
import { PortalService } from './portal.service';
import { ListService } from './list.service';
import { FileService } from './file.service';
import { CoreService } from './core.service';
import { SignalRService } from './signalr.service';
import { SafeHtmlPipe } from './search-main/safe-html.pipe';
import { VersionAlertComponent } from './version-alert/version-alert.component';
import { PropertyService } from '../property/property.service';
import { PopupComponent } from './popup/popup.component';

/*  do not import SharedModule here - that's a no-no  */

@NgModule({
    imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TopMenuComponent,
    LeftMenuComponent,
    LeftMenuButtonComponent,
    SideSubMenuComponent,
    AlertComponent,
    SearchResultPopupComponent,
    SearchMainComponent,
    VersionAlertComponent,
    PopupComponent,
    SafeHtmlPipe
],
    exports: [
    TopMenuComponent,
    LeftMenuComponent,
    SideSubMenuComponent,
    AlertComponent,
    VersionAlertComponent,
    SearchResultPopupComponent,
    PopupComponent
],
    providers: [
        LoggerService,
        SubMenuService,
        AlertService,
        SearchService,
        MenuService,
        LookupService,
        ListService,
        UserService,
        PropertyService,
        PortalService,
        CoreService,
        SignalRService,
        FileService
    ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

