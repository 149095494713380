import { Address } from 'src/app/shared/address';
import { catchError } from 'rxjs/operators';
import { SpaceChangeLog, MasterEditFormat, PropertyAcre, PropertyAdvance } from './property';

import { throwError as observableThrowError, Observable, ReplaySubject } from 'rxjs';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Building, LeaseSummary, OccupancyHistory, PortfolioSummary, Property, PropertyAccountTree, QuarterlyAppraisal, SameStoreSummary, Tax, Unit, ArAgingDetail, LeasingActivity, DueDiligenceJobs } from '.';
import { ConstructionSummary } from '../construction/constructionSummary';
import { SaveResult } from '../core/saveResult';
import { AlertService } from '../core/alert.service';
import { Lease } from '../lease/lease';
import { Disposition } from './disposition';
import { Inspection } from './inspection';
import { User } from '../user/user';
import { Utility } from '../misc/utility/utility';
import { PropertyBudgetEntry, PropertyBudgetSummary } from './';
import { Contractor } from '../misc/contractor/contractor';
import { ConstructionBudget } from '../construction';
import { UserService } from '../core/user.service';
import { PropertyQuarterlyMetric } from './propertyQuarterlyMetric';

@Injectable()
export class PropertyService {
  private propertyUrlCloud = environment.cubCloudUrl + 'properties';
  private propertyContact = environment.cubCloudUrl + 'propertyContact';
  private propertyAddressesUrl = environment.cubCloudUrl + 'address';

  private propList = new ReplaySubject<Property[]>(1);
  properties$ = this.propList.asObservable(); // $ is onnvention to indicate observable
  private selectedId = new ReplaySubject<string>(1);
  selectedId$ = this.selectedId.asObservable();
  private listSettings = new ReplaySubject<any>(1);
  listSettings$ = this.listSettings.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private alertService: AlertService) {
    this.getProperties();
  }

  getProperties() {
    let url = `${this.propertyUrlCloud}`;
    this.http.get<Property[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).subscribe(props => {
      this.propList.next(props);
    });
  }

  getImagePaths(id: string): Observable<string[]> {
    const url = `${this.propertyUrlCloud}/${id}/imagePaths`;
    return this.http.get<string[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTotalFmv(): Observable<number> {
    const url = `${this.propertyUrlCloud}/totalfmv`;
    return this.http.get<number>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getOccupancy(id: string): Observable<OccupancyHistory> {
    const url = `${this.propertyUrlCloud}/${id}/occupancy`;
    return this.http.get<OccupancyHistory>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getDispositions(id: string): Observable<Disposition[]> {
    const url = `${this.propertyUrlCloud}/${id}/dispos`;
    return this.http.get<Disposition[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getAppraisalHistory(id: string): Observable<QuarterlyAppraisal[]> {
    const url = `${this.propertyUrlCloud}/${id}/appraisalHistory`;
    return this.http.get<QuarterlyAppraisal[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getAppraisalHistoryForProps(ids: number[]): Observable<QuarterlyAppraisal[]> {
    const url = `${this.propertyUrlCloud}/appraisalHistory`;
    return this.http.post<QuarterlyAppraisal[]>(url, ids, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getTenantHistory(id: string): Observable<LeaseSummary[]> {
    const url = `${this.propertyUrlCloud}/${id}/tenantHistory`;
    return this.http.get<LeaseSummary[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getBuildings(id: string): Observable<Building[]> {
    const url = `${this.propertyUrlCloud}/${id}/buildings`;
    return this.http.get<Building[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getQuarterlyMetrics(id: string): Observable<PropertyQuarterlyMetric[]> {
    const url = `${this.propertyUrlCloud}/${id}/quarterlyMetrics`;
    return this.http.get<PropertyQuarterlyMetric[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getUnits(id: string): Observable<Unit[]> {
    const url = `${this.propertyUrlCloud}/${id}/units`;
    return this.http.get<Unit[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getAllUnits(): Observable<Unit[]> {
    const url = `${this.propertyUrlCloud}/units`;
    return this.http.get<Unit[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getAllBuildings(): Observable<Building[]> {
    const url = `${this.propertyUrlCloud}/buildings`;
    return this.http.get<Building[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getCertificationWarnings(id: string): Observable<string[]> {
    const url = `${this.propertyUrlCloud}/${id}/getCertificationWarnings`;
    return this.http.get<string[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getPortfolioSummary(): Observable<PortfolioSummary[]> {
    const url = `${this.propertyUrlCloud}/portfolioSummary`;
    return this.http.get<PortfolioSummary[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getFormerLeases(id: string): Observable<Lease[]> {
    const url = `${this.propertyUrlCloud}/${id}/formerLeases`;
    return this.http.get<Lease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getFormerLeasesForUnit(unitKey: number): Observable<Lease[]> {
    const url = `${this.propertyUrlCloud}/units/${unitKey}/formerLeases`;
    return this.http.get<Lease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getInspections(propId: string): Observable<Inspection[]> {
    const url = `${this.propertyUrlCloud}/${propId}/inspections`;
    return this.http.get<Inspection[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getPendingLeasesForUnit(unitKey: number): Observable<Lease[]> {
    const url = `${this.propertyUrlCloud}/units/${unitKey}/pendingLeases`;
    return this.http.get<Lease[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getInspection(propId: string, inspectionId: number): Observable<Inspection> {
    const url = `${this.propertyUrlCloud}/${propId}/inspections/${inspectionId}`;
    return this.http.get<Inspection>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getSameStoreHistory(id: string): Observable<SameStoreSummary[]> {
    const url = `${this.propertyUrlCloud}/${id}/sameStoreHistory`;
    return this.http.get<SameStoreSummary[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getSameStoreHistoryForProps(keys: number[]): Observable<SameStoreSummary[]> {
    const url = `${this.propertyUrlCloud}/sameStoreHistory`;
    return this.http.post<SameStoreSummary[]>(url, keys, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getArAgingDetail(ids: string[]): Observable<ArAgingDetail[]> {
    const url = `${this.propertyUrlCloud}/getARAgingDetail`;
    return this.http.post<ArAgingDetail[]>(url, ids, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getGRESB(id: string): Observable<string[]> {
    const url = `${this.propertyUrlCloud}/${id}/gresb`;
    return this.http.get<string[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getAcres(propertyId: string): Observable<PropertyAcre[]> {
    const url = `${this.propertyUrlCloud}/${propertyId}/acres`;
    return this.http.get<PropertyAcre[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getMasterEditFields() {  // this doesn't work - but isn't used anyway - skipping for now but keeping just in case we use it in the future
    const url = this.propertyUrlCloud + `/data/masterEdit.json?v=` + Date.now();
    let list = this.http.get<MasterEditFormat>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getContractorsForUnit(unitKey: number): Observable<Contractor[]> {
    const url = `${this.propertyContact}/contractorsForUnit/${unitKey}`;
    return this.http.get<Contractor[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  saveContractor(c: Contractor) {
    const url = `${this.propertyContact}/contractors`;
    return this.http.post<Contractor>(url, c, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  savePropertyAddress(address: Address) {
    console.log('address', address);
    const url = `${this.propertyAddressesUrl}/${address.addressKey}`;
    return this.http.put(url, address, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  addDispositionItem(id: string, packageKey: string, classKey: string): Observable<boolean> {
    const url = `${this.propertyUrlCloud}/addDisposition`;
    return this.http.post<boolean>(url, { propertyId: id, packageKey, classKey }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getEditSpaceChangeLog(propertyId: string) {
    const url = `${this.propertyUrlCloud}/${propertyId}/spaceChangeLog`;
    return this.http.get<SpaceChangeLog[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  addToSpaceChangeLog(propertyId: string, spaceChangeLog: SpaceChangeLog) {
    const url = `${this.propertyUrlCloud}/${propertyId}/addToSpaceChangeLog`;
    return this.http.post(url, spaceChangeLog, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  saveAcres(propertyId: string, a: PropertyAcre) {
    const url = `${this.propertyUrlCloud}/${propertyId}/acres`;
    return this.http.post<boolean>(url, a, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  saveInspection(propId: string, inspection: Inspection): Observable<Inspection> {
    const url = `${this.propertyUrlCloud}/${propId}/inspections/${inspection.id}`;
    return this.http.post<Inspection>(url, JSON.stringify(inspection), {
      headers: this.userService.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  verifyForPromotion(id: string): Observable<string[]> {
    const url = `${this.propertyUrlCloud}/${id}/verifyForPromotion`;
    return this.http.get<string[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  savePropertiesBasis(ps: Property[]): Observable<string> {
    const url = `${this.propertyUrlCloud}/basis`;
    return this.http.post<string>(url, ps, {
      headers: this.userService.getUrlUserHeaders('application/json'),
      withCredentials: false
    });
  }

  certify(id: string): Observable<boolean> {
    const url = `${this.propertyUrlCloud}/${id}/certify`;
    return this.http.post<boolean>(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  rename(propertyId: string, oldPath: string, newName: string, newPath: string, failOnDirectoryRenameFail: boolean): Observable<string> {
    const url = `${this.propertyUrlCloud}/${propertyId}/rename`;
    return this.http.post(url, { newName: newName, oldPath: oldPath, newPath: newPath, failOnDirectoryRenameFail: failOnDirectoryRenameFail }, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false, responseType: 'text' });
  }

  getSoldProperties(): Observable<Property[]> {
    const url = `${this.propertyUrlCloud}/sold`;
    return this.http.get<Property[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getProperty(id: string): Observable<Property> {
    const url = `${this.propertyUrlCloud}/${id}`;
    return this.http.get<Property>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getLeasingActivity(ids: string[], startDt: string, endDt: string): Observable<LeasingActivity[]> {
    let start = startDt.replace('/', '-');
    let end = endDt.replace('/', '-');
    const url = `${this.propertyUrlCloud}/leasingActivity/${start}/${end}`;
    return this.http.post<LeasingActivity[]>(url, ids, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getConstructionBudgets(id: string): Observable<ConstructionSummary[]> {
    const url = `${this.propertyUrlCloud}/${id}/constructionBudgets`;
    return this.http.get<ConstructionSummary[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getConstructionBudgetsForProperties(ids: string[]): Observable<ConstructionBudget[]> {
    const url = `${this.propertyUrlCloud}/budgets/forProperties`;
    return this.http.post<ConstructionBudget[]>(url, ids, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getSameStoreDetail(id: string, fiscalYr: string): Observable<PropertyAccountTree[]> {
    const url = `${this.propertyUrlCloud}/${id}/sameStoreDetail/${fiscalYr}`;
    return this.http.get<PropertyAccountTree[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getNOIDetail(id: string): Observable<PropertyAccountTree[]> {
    const url = `${this.propertyUrlCloud}/${id}/noiDetail`;
    return this.http.get<PropertyAccountTree[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  getBudgetDetails(propertyId: string, year: number) {
    const url = `${this.propertyUrlCloud}/${propertyId}/budgetData/${year}`;
    let list = this.http.get<PropertyBudgetEntry[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getBudgetSummary(propertyId: string, year: number) {
    const url = `${this.propertyUrlCloud}/${propertyId}/budgetSummary/${year}`;
    let list = this.http.get<PropertyBudgetSummary[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
    return list;
  }

  getYardiJobs(id: string, hMy: number): Observable<DueDiligenceJobs[]> {
    const url = `${this.propertyUrlCloud}/${id}/ddjobs/${hMy}`;
    return this.http.get<DueDiligenceJobs[]>(url, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  addBuildings(propertyId: string, buildingCount: number) {
    const url = `${this.propertyUrlCloud}/${propertyId}/addBuildings/${buildingCount}`;
    return this.http.post(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  addUnits(propertyId: string, buildingKey: number, unitCount: number) {
    const url = `${this.propertyUrlCloud}/${propertyId}/addUnits/${buildingKey}/${unitCount}`;
    return this.http.post(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  saveUnitSpace(propertyId: string, units: Unit[]) {
    const url = `${this.propertyUrlCloud}/${propertyId}/saveUnitSpace`;
    return this.http.post(url, units, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  advance(id: string, adv: PropertyAdvance): Observable<boolean> {
    const url = `${this.propertyUrlCloud}/${id}/advance`;
    return this.http.post<boolean>(url, adv, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false });
  }

  sendToYardi(property: Property) {
    const url = `${this.propertyUrlCloud}/sendToYardi`;
    return this.http.post<boolean>(url, JSON.stringify(property), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  addCorporateProperty(id: string, desc: string, city: string, state: number, category: number, type: number) {
    const url = `${this.propertyUrlCloud}/addProperty`;
    let user = JSON.parse(localStorage.getItem('user')) as User;
    let values = JSON.stringify({ propertyId: id, propertyDesc: desc, city, stateKey: state, categoryKey: category, typeKey: type, userId: user.accountName });

    return this.http.post(url, values, { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false, responseType: 'text' }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  checkExists(id: string) {
    const url = `${this.propertyUrlCloud}/checkExists`;
    return this.http.post<boolean>(url, JSON.stringify(id), { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  createNOIExport(): Observable<string> {
    const url = `${this.propertyUrlCloud}/noiExport`;
    //return this.http.post(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false }).pipe(catchError(err => this.handleError(err, this.alertService)));
    return this.http.post(url, '', { headers: this.userService.getUrlUserHeaders('application/json'), withCredentials: false, responseType: 'text' }).pipe(catchError(err => this.handleError(err, this.alertService)));
  }

  private handleError(error: HttpErrorResponse | any, alertService: AlertService) {
    let errMsg: string;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errMsg = error.error.message;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong
      errMsg = error.error;
      console.error(`Backend returned code ${error.status}, ` + `body was: ${JSON.stringify(error.error)}`);
    }
    alertService.error('Error: ' + errMsg);
    // return an observable with a user-facing error message
    return observableThrowError('Error: ' + error.error);
  }
}
